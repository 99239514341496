import React from 'react';
import { Bar } from 'react-chartjs-2';
import { toast } from "react-toastify";

export const LoadStats = (globalUrl, cachekey) => {
	if (globalUrl === undefined) {
		console.log("Global URL is undefined")
		return
	}

	if (cachekey === undefined) {
		console.log("Cachekey is undefined")
		return
	}

	const url = `${globalUrl}/api/v1/stats/${cachekey}`
	const resp = fetch(url, {
		method: "GET",
		credentials: "include",
	})
	resp.then((resp) => {
		console.log("Response: ", resp)
	})
	resp.catch((err) => {
		console.log("Error: ", err)
	})
	const data = resp.data
	console.log("Data: ", resp, data)

	return {
		"labels": [
			"Sep 11, 2024 \nUptime: 100.00%",
			"Sep 10, 2024 \nUptime: 100.00%",
			"Sep 09, 2024 \nUptime: 100.00%",
			"Sep 08, 2024 \nUptime: 100.00%",
			"Sep 07, 2024 \nUptime: 100.00%",
			"Sep 06, 2024 \nUptime: 100.00%",
			"Sep 05, 2024 \nUptime: 98.99%",
			"Sep 04, 2024 \nUptime: 43.75%",
			"Sep 03, 2024 \nUptime: 0.00%",
			"Sep 02, 2024 \nUptime: 0.00%",
			"Sep 01, 2024 \nUptime: 0.00%",
			"Aug 31, 2024 \nUptime: 0.00%",
			"Aug 30, 2024 \nUptime: 0.00%",
			"Aug 29, 2024 \nUptime: 0.00%",
			"Aug 28, 2024 \nUptime: 0.00%",
			"Aug 27, 2024 \nUptime: 0.00%",
			"Aug 26, 2024 \nUptime: 0.00%",
			"Aug 25, 2024 \nUptime: 0.00%",
			"Aug 24, 2024 \nUptime: 0.00%",
			"Aug 23, 2024 \nUptime: 0.00%",
			"Aug 22, 2024 \nUptime: 0.00%",
			"Aug 21, 2024 \nUptime: 0.00%",
			"Aug 20, 2024 \nUptime: 0.00%",
			"Aug 19, 2024 \nUptime: 0.00%",
			"Aug 18, 2024 \nUptime: 0.00%",
			"Aug 17, 2024 \nUptime: 0.00%",
			"Aug 16, 2024 \nUptime: 0.00%",
			"Aug 15, 2024 \nUptime: 0.00%",
			"Aug 14, 2024 \nUptime: 0.00%",
			"Aug 13, 2024 \nUptime: 0.00%",
			"Aug 12, 2024 \nUptime: 0.00%"
		],
		"datasets": [
			{
				"label": "",
				"data": [
					1,
					1,
					1,
					1,
					10,
					2,
					3,
					1,
					0,
					1,
					2,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1,
					1
				],
				"backgroundColor": [
					"#00F670",
					"#00F670",
					"#00F670",
					"#00F670",
					"#00F670",
					"#00F670",
					"#FFD700",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C",
					"#FF354C"
				],
				"barThickness": 30, 
			}
		]
	}
}

const DashboardBarchart = (props) => {
	const { timelineData, } = props;

	const barOptions = {
	    plugins: {
	      tooltip: {
	        zIndex: 1000,  // Make sure it's higher than any other elements
	      },
	    },
	    tooltips: {
	      mode: 'nearest',
	      intersect: false,
	      position: 'nearest', // Makes the tooltip follow the cursor closely
	    },
        legend: {
            display: false
        },
        layout: {
            padding: {
                top: 0, // Adjust the top padding as needed
                bottom: 0, // Adjust the bottom padding as needed
                left: 0, // Adjust the left padding as needed
                right: 0, // Adjust the right padding as needed
            },
        },
        scales: {
            yAxes: [{
                ticks: {
                    display: false
                },
				beginAtZero: true,
            }],
			y: {
				beginAtZero: true,
			},
            xAxes: [{
                ticks: {
                    display: false
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const label = data.labels[tooltipItem.index];
                    return label.split('\n')[0]; // Return only the date part
                },
                afterLabel: function (tooltipItem, data) {
                    const label = data.labels[tooltipItem.index];
                    // console.log(label)
                    const uptime = label.match(/Uptime:\s*(\d+(?:\.\d+)?)/)[1]; // Extract uptime value using regex
                    return `Success Rate: ${uptime}%`; // Customize the uptime display
                },
                title: function () {
                    return 'Fully Operational'; // Hide the tooltip title
                }
            }
        }
    }

	return (
		<Bar
	        data={timelineData}
	        options={barOptions}
			height={15}
	        getElementAtEvent={(elements) => {
	      	  if (elements && elements.length > 0) {
	      		  toast("Click event")
	      		  console.log("Clicked: ", elements)
	      	  }
	        }}
	    />
	)
}

export default DashboardBarchart;
